import { useState } from "react";
import type { MetaFunction } from "@remix-run/node";
import { Link } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import { PageShell } from "~/components/layouts/page-shell";
import { ArrowRightIcon, LightningBoltIcon } from "@radix-ui/react-icons";
import { IntegrationSetupCard } from "~/components/integration-setup-card";
import { Images } from "~/lib/images";
import { FaShieldAlt, FaExpandArrowsAlt } from "react-icons/fa";
import { IntegrationModel } from "~/lib/data";
import { ToggleDisplay } from "~/components/controls/feature";

export const meta: MetaFunction = () => {
  return [
    { title: "QuickBridge: Seamless Data Integration Platform" },
    {
      name: "description",
      content:
        "Streamline your business workflows with QuickBridge's powerful integrations. Connect your tools effortlessly and boost productivity.",
    },
  ];
};

const featuredIntegrations = [
  {
    id: "salesforce-hubspot",
    source: {
      name: "QuickBooks Online",
      image: Images.Integration.QuickBooksOnline,
    },
    target: { name: "EFRIS", image: Images.Integration.EFRIS },
    description: "Sync customer data between QuickBooks Online and EFRIS",
    category: "Tax",
  },
  {
    id: "shopify-quickbooks",
    source: {
      name: "QuickBooks Desktop",
      image: Images.Integration.QuickBooksDesktop,
    },
    target: { name: "EFRIS", image: Images.Integration.EFRIS },
    description:
      "Automate order and financial data transfer from QuickBooks Desktop to EFRIS.",
    category: "Tax",
  },
  {
    id: "mailchimp-stripe",
    source: { name: "Insta", image: Images.Integration.InstaHealth },
    target: {
      name: "QuickBooks Desktop",
      image: Images.Integration.QuickBooksDesktop,
    },
    description: "Connect your Insta Health data to QuickBooks Desktop.",
    category: "Healthcare",
  },
];

export default function Index() {
  const [email, setEmail] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle email submission logic here
    console.log("Email submitted:", email);
    setEmail("");
  };

  return (
    <PageShell className="bg-gradient-to-br from-slate-50 to-emerald-100/50 dark:from-slate-900 dark:to-ceramic-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <header className="text-center mb-20">
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-slate-900 dark:text-white mb-6">
            Seamless Integrations for{" "}
            <span className="text-emerald-600 dark:text-emerald-400">
              Smarter Business
            </span>
          </h1>
          <p className="text-xl text-slate-600 dark:text-slate-300 max-w-2xl mx-auto mb-10">
            Connect your essential tools, automate workflows, and unlock the
            full potential of your data with QuickBridge.
          </p>
          <ToggleDisplay>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4"
            >
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full sm:w-64 px-4 py-2 rounded-md border border-slate-300 focus:outline-none focus:ring-2 focus:ring-emerald-500 dark:bg-slate-800 dark:border-slate-600 dark:text-white"
                required
              />
              <Button
                type="submit"
                size="lg"
                className="w-full sm:w-auto bg-emerald-600 hover:bg-emerald-700 text-white transition-colors duration-300"
              >
                Get Started <ArrowRightIcon className="ml-2" />
              </Button>
            </form>
          </ToggleDisplay>
        </header>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-slate-900 dark:text-white text-center mb-10">
            Featured Integrations
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {featuredIntegrations.map((integration) => (
              <IntegrationSetupCard
                key={integration.id}
                integration={integration as IntegrationModel}
              />
            ))}
          </div>
          <div className="text-center mt-8">
            <Link to="/integrations">
              <Button
                variant="outline"
                className="transition-colors duration-300 border-emerald-600 text-emerald-600 hover:bg-emerald-100 dark:border-emerald-400 dark:text-emerald-400 dark:hover:bg-emerald-900"
              >
                Explore All Integrations
              </Button>
            </Link>
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-slate-900 dark:text-white text-center mb-10">
            Why Choose QuickBridge?
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white dark:bg-slate-800 rounded-lg p-6 shadow-md">
              <LightningBoltIcon className="w-12 h-12 text-emerald-500 mb-4" />
              <h3 className="text-xl font-semibold mb-2">
                Lightning-Fast Setup
              </h3>
              <p className="text-slate-600 dark:text-slate-300">
                Connect your tools in minutes with our intuitive interface.
              </p>
            </div>
            <div className="bg-white dark:bg-slate-800 rounded-lg p-6 shadow-md">
              <FaShieldAlt className="w-12 h-12 text-emerald-500 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Secure & Reliable</h3>
              <p className="text-slate-600 dark:text-slate-300">
                Your data is protected with enterprise-grade security measures.
              </p>
            </div>
            <div className="bg-white dark:bg-slate-800 rounded-lg p-6 shadow-md">
              <FaExpandArrowsAlt className="w-12 h-12 text-emerald-500 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Scalable Solution</h3>
              <p className="text-slate-600 dark:text-slate-300">
                Grow your business with a platform that scales with you.
              </p>
            </div>
          </div>
        </section>

        <section className="bg-emerald-600 text-white rounded-lg p-8 text-center">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Streamline Your Workflow?
          </h2>
          <p className="text-xl mb-8">
            Join thousands of businesses using QuickBridge to boost their
            productivity.
          </p>
          <Link to="/signup">
            <Button
              size="lg"
              className="bg-white text-emerald-600 hover:bg-slate-100 transition-colors duration-300"
            >
              Start Your Free Trial
            </Button>
          </Link>
        </section>
      </div>
    </PageShell>
  );
}
